<template>
 <div class="content">
    <div class="con_footer">
      <div class="footer_title"><span>{{user.notitle}}</span>{{user.description}}
       </div>
      <div class="down_app_code">
        <div class="app_code_div" v-for="(item,index) in app_list" :key="index" @mouseenter="app_list[index].type = 1" @mouseleave="app_list[index].type = 0">
          <img class="app_code_div_logo" :src="item.img" alt="">
          <div class="app_code_div_dialog" v-if="item.type">
            <img :src="item.ewm" alt="">
          </div>
        </div>
      </div>
      <div class="footer_site">
          <div class="site_item">
            <img class="site_item_img" src="https://qiniu.fanyu889.com/cxmx/assets/ydimg/9.png"  style="width: 0.7rem;height: 0.85rem"/>
            <div>
              <p>{{user.address}}</p>
            </div>
          </div>
        <div class="site_item">
          <img class="site_item_img" src="https://qiniu.fanyu889.com/cxmx/assets/ydimg/10.png" style="width: 0.7rem;height: 0.7rem" />
          <div>
            <p>电话</p>
            <p>{{user.tel}}</p>
          </div>
        </div>
        <div class="site_item">
          <img class="site_item_img" src="https://qiniu.fanyu889.com/cxmx/assets/ydimg/19.png" style="width: 0.85rem;height: 0.63rem" />
          <div>
            <p>邮箱</p>
            <p>{{user.email}}</p>
          </div>
        </div>
      </div>
      <div class="footer_code">
        <div><img :src="user.appewm" alt=""></div>
        <p>扫码下载APP</p>
      </div>
      <div class="footer_xian"></div>
      <div class="footer_logo">
        <img :src="user.logo" />
        <p @click="bah_tz">{{user.copyright}} <span>备案号：</span>{{user.record}}</p>
      </div>
    </div>
 </div>
</template>

<script>

import { mapState } from 'vuex';
export default {
  name: "index",
  data(){
    return{
      app_list:[
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/12.png',type:0,ewm:''},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/15.png',type:0,ewm:''},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/24.png',type:0,ewm:''},
        {img:'https://qiniu.fanyu889.com/cxmx/assets/pcimg/28.png',type:0,ewm:''},
      ],
    }
  },
  created() {
    this.all_user();
  },

  computed: {
    ...mapState(['user']),
  },
  mounted() {
  },
  methods:{
    all_user(){

      this.app_list[0].ewm=this.user.gzhewm;
      this.app_list[1].ewm=this.user.sphewm;
      this.app_list[2].ewm=this.user.wxewm;
      this.app_list[3].ewm=this.user.dyewm;
    },
    //备案号通知
    bah_tz(){
      window.open("https://beian.miit.gov.cn/");
    }
  }
}
</script>

<style scoped lang="scss">
 .content{
   width: 100%;
   height: 12rem;
   background: #222222;
   padding-top: 1px;
   .con_footer{
     width: 100%;
     height: 11.3rem;
     margin: 0 auto;
     position: relative;
     .footer_title{
       margin-top: 0.4rem;
       margin-left: 0.55rem;
       img{
         width: 2.9rem;
         height: 0.65rem;
         vertical-align: middle;
         margin-right: 0.1rem;
       }
       span{
         font-weight: bold;
         font-size: 0.5rem;
         color: #FFFFFF;
         background: linear-gradient(0deg, #FFB84D 0%, #FFF7CA 100%);
         -webkit-background-clip: text;
         -webkit-text-fill-color: transparent;
         letter-spacing: 0.05rem;
         position: relative;
         top: 0.01rem;
       }
       width: 13.6rem;
       font-weight: 500;
       font-size: 0.35rem;
       color: #CACACA;
       line-height: 0.65rem;

     }
     .down_app_code{
       width: 6.4rem;
       height: 1.15rem;
       display: flex;
       justify-content: space-between;
       position: absolute;
       left:0.65rem;
       top:6rem;
       .app_code_div{
         width: 1.15rem;
         height: 1.15rem;
         position: relative;
         .app_code_div_logo{
           width: 1.15rem;
           height: 1.15rem;
         }
         .app_code_div_dialog{
           background: url('https://qiniu.fanyu889.com/cxmx/assets/ydimg/24.png') top center no-repeat;
           background-size: 100%;
           width:4.5rem;
           height: 4.83rem;
           position: absolute;
           left: -52%;
           top: -5rem;
           text-align: center;
           img{
             width: 4rem;
             height: 4rem;
             margin-top: 0.25rem;
           }
         }
       }
     }
     .footer_site{
       display: flex;
       justify-content: space-between;
       width: 17rem;
       position: absolute;
       top:7.8rem;
       left: 50%;
       transform: translateX(-50%);
       .site_item{
         width:5rem;
         display: flex;
         .site_item_img{
           margin-top: 0.3rem;
           margin-right: 0.25rem;
         }
        div{
          margin-left: 10px;
          padding-top: 2px;
          p:nth-child(1){
            font-weight: 400;
            font-size: 0.45rem;
            color: #ADADAD;
            line-height: 0.6rem;
          }
          p:nth-child(2){
            font-weight: 400;
            font-size: 0.45rem;
            color: #ADADAD;
            line-height: 0.6rem;
          }
        }
       }
     }
     .footer_code{
       width:3.33rem;
       position: absolute;
       top: 0.87rem;
       right: 0.6rem;
       div{
         width: 3.33rem;
         height: 3.33rem;
         img{
           width: 3.33rem;
           height: 3.33rem;
           border-radius:0.33rem;
         }
       }
       p {
         width: 3.33rem;
         height: 1.08rem;
         background: linear-gradient(90deg, #FFB84D, #FFF7CA);
         border-radius: 0.15rem;
         text-align: center;
         line-height: 1.08rem;
         font-weight: bold;
         font-size: 0.5rem;
         color: #000000;
         margin-top: 0.25rem;
       }
     }

     .footer_logo{
       display: flex;
       justify-content: space-between;
       position: absolute;
       height: 1.2rem;
       bottom: 0.5rem;
       width: 17.58rem;
       left: 50%;
       transform: translateX(-50%);
       img{
         display: block;
         width: 3.75rem;
         height: 1.2rem;
       }
       p{
         line-height:1.2rem;
         font-weight: 400;
         font-size: 0.4rem;
         color: #ADADAD;
         span{
           margin-left: 0.4rem;
         }
       }
     }
   }

 }
</style>