<template>
<div class="contnav">
  <div class="nav_back">
    <img :src="user.noimg" alt="">
    <p>流静冥想</p>
    <p>带你探索内心的宁静与力量</p>
  </div>
</div>
</template>

<script>


import {mapState} from "vuex";

export default {
  name: "nav_bj",
  data(){
     return{

     }
  },  computed: {
    ...mapState(['user']),
  },

  mounted() {

  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
 .contnav{
   .nav_back{
     width: 100%;
     height: 11.18rem;
     position: relative;
     img{
       width: 100%;
       height: 11.18rem;
       position: absolute;
       top: 0;
       left: 0;
     }
     p:nth-child(2){
       font-weight: bold;
       font-size: 1.15rem;
       color: #FFFFFF;
       position: absolute;
       left: 3.8rem;
       top: 4.95rem;
     }
     p:nth-child(3){
       font-weight: 500;
       font-size: 0.85rem;
       color: #FFFFFF;
       position: absolute;
       left: 3.88rem;
       top: 6.5rem;

     }
   }
 }
</style>