<template>
 <div class="contnav">
   <div class="nav_div">
     <img :src="user.logo" alt="" class="nav_logo">
     <img src="https://qiniu.fanyu889.com/cxmx/assets/ydimg/30.png" alt="" class="nav_zk" @click="zk =! zk">

   </div>
   <el-collapse-transition>
   <div class="nav_tabs" v-show="zk">

     <div v-for="(item ,index) in list" :key="index" @click="router_but(index)">
       <p :class="item.type == 1 ? 'nav_xz':'' ">{{item.name}}</p>
       <p v-if="item.type ==1"></p>
     </div>

   </div>
   </el-collapse-transition>
 </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import Vue from 'vue'
import {mapState} from "vuex";

Vue.component(CollapseTransition.name, CollapseTransition)

export default {
  name: "index",
  data(){
    return{
      list:[
        {name:'首页',type:0,path:'/'},
        {name:'官方活动',type:0,path:'/activity'},
        {name:'冥想资讯',type:0,path:'/message'},
        {name:'隐私政策',type:0,path:'/privacy'},
        {name:'关于我们',type:0,path:'/about'},
        {name:'加入我们',type:0,path:'/join'}
      ],
      zk:false

    }
  },
  created() {
    this.router_jt();
  },
  mounted() {

  },
  computed: {
    ...mapState(['user']),
  },
  methods:{

    router_jt(){
      const currentPath = this.$route.path;
      if (this.$route.path == '/mesdetail'){
        this.list[2].type =1;
        return false;
      }
      for (let i=0; i<  this.list.length;i++ ){
        if ( currentPath == this.list[i].path){
          this.list[i].type =1;
        }else {
          this.list[i].type =0;
        }
      }



    },
    router_but(val){
      this.zk=false;
      this.$router.push({ path:this.list[val].path });

    },

  }
}
</script>

<style scoped lang="scss">
 .contnav{
   width: 100%;
   background: rgba(0,0,0,0.6);
   position: fixed;
   top: 0;
   z-index: 9999;
   .nav_div{
     height: 2.55rem;
     display: flex;
     justify-content: space-between;
     position: relative;
     .nav_logo{
       width: 4.9rem;
       height: 1.5rem;
       position: relative;
       top: 50%;
       transform: translateY(-50%);
       margin-left: 0.8rem;

     }
     .nav_zk{
       width: 0.78rem;
       height: 0.65rem;
       position: absolute;
       right: 0.98rem;
       top: 50%;
       transform: translateY(-50%) ;
     }

   }
   .nav_tabs{
     width: 100%;
     //height: 0;
    // animation: rotateInDownRight 0.5s linear infinite;
     div{
       width: 16.79rem;
       height: 2.33rem;
       text-align: center;
       line-height: 2.33rem;
       border-bottom: 1px solid #FFFFFF;
       margin: 0 auto;
       color: white;
       font-size: 0.75rem;
     }
     @keyframes rotateInDownRight {
       0% {
          height: 0%;
       }
       100% {
          height: 100%;
       }
     }
   }


 }
</style>